import "./App.scss";
import oven from "./img/steamy-oven.mov";
import ovenPoster from "./img/steamy-oven-poster.jpg";

function App() {
  return (
    <div className="global-wrapper">
      <video
        autoPlay={true}
        poster={ovenPoster}
        preload="none"
        muted
        playsInline
        loop
        id="steamy-oven"
      >
        <source src={oven} />
      </video>
      <div className="main-container container d-flex justify-content-center">
        <div className="col-11 col-md-8 col-lg-6">
          <h1>Jade Fung</h1>
          <h2>
            <a href="https://github.com/overwatchcorp">React Engineer</a>
            {" & "}
            <a href="https://instagram.com/sleepycatpdx">Baker</a>{" "}
            in Portland, Oregon.
          </h2>
          <h4>About Me</h4>
          <p>
            Hello! I'm an engineer who applies my skills and learnings to good
            code and good bread. I love to see the fruits of my labors being
            enjoyed by friends and peers, which is why I'm drawn to sharable and
            universal mediums.
          </p>
          <h4>Background</h4>
          <p>
            I've been coding since I was in high school and have a long history
            of exploring and teaching myself technologies. I've been interested
            in web for years and have been working with React since
            college.
          </p>
          <p>
            Most recently, I was the lead engineer on the React template used for
            every client project at Soul Machines. I spearheaded a renewed
            effort to re-write the template from the ground up. I focused on
            simplifying the structure and reducing the learning curve for
            other engineers who are adapting the template for their first time.
          </p>
          <p>
            I'm currently looking for mid-level to senior frontend or full-stack engineering
            roles working on a meaningful product that does good for the world.
            If you're hiring and have a role that might fit, please reach out to
            me at <a href="mailto:hello@fungj.net">hello@fungj.net</a>!
          </p>
          <div className="foot d-flex justify-content-center">
            <a href="https://github.com/overwatchcorp" className="me-2">
              Github
            </a>
            <a href="https://www.linkedin.com/in/jade-fung/" className="me-2">
              LinkedIn
            </a>
            <a href="https://www.instagram.com/sleepycatpdx/" className="me-2">
              Instagram
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
